:root{
  --textColor:#777373;
  --purpleColor: #BA68C8;
  --mainBlack: #1A1A1A;
  --logoText:'Updock', cursive;
  --logo2Text: 'Neonderthaw', cursive;
  --headingFamily: 'Raleway', sans-serif;
  --textFamily: 'Lato', sans-serif;
  --secondaryColor: #e0e4f0;
  --primaryColor: #0de3eb;
  --text2Color: #646262;
  --accentColor: #ED7D3A;

  --backgroundColor: #0b081b;
  --backgroundColor2: #e5e5e959;
  --pinkColor: #ff2600;
  --fadePink: #ff260080;
}
.custom-select {
  min-width: 350px;
}

.custom-select select {
  appearance: none;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}
.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}
.custom-select::before {
  border-left: 1 solid transparent;
  border-right: 1 solid transparent;
  border-bottom: 1 solid yellow;
  top: 40%;
}

.custom-select::after {
  border-left: 1 solid transparent;
  border-right: 1 solid transparent;
  border-top: 1 solid black;
  top: 55%;
}
.buttonLink{
  border: none;
  border-radius: 5px;
  background: var(--primaryColor);
  color: #fff;
  font-family: var(--headingFamily);
  padding: 15px 30px;
  text-decoration: none;
  font-weight: normal;
}
.pageTitle{
  font-family: var(--headingFamily);
  color: #000000;
  font-size: xx-large;
  position: relative;
  padding-bottom: 20px;
}
.pageTitle .line{
  position: absolute;
  top: 38px;
  background: #2678d1;
  height: 8px;
  width: 200px;
}
@media screen and (max-width: 450px) {
  .pageTitle{
      font-size: x-large;
  }
  .pageTitle .line{
    top: 29px;
    height: 5px;
    width: 100px;
  }
}

.pageText{
  color: var(--textColor);
  font-size: 16px;
  font-weight: normal;
  font-family: var(--textFamily);
}